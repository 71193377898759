import React from "react";

function Header() {
    return (
        <header>
            <h1 className="heading">Hey, <br /> I'm Akif</h1>
        </header>
    )
}

export default Header;