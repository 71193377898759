import React from 'react';

function Contact() {
    return (
        <section className='contact' id='contact'>
            <h2 className="title">Let's talk!</h2>
            <a href='mailto:akiif.dev@gmail.com?subject=Get In Touch&body=Hey Akif,' className="btn">CONTACT ME</a>
        </section>
    );
}

export default Contact;